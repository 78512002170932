import React from 'react'
import PropTypes from 'prop-types'

import Img from 'gatsby-image/withIEPolyfill'
import { Link } from 'gatsby'

import { makeStyles } from '@material-ui/core/styles'
import { useIntl } from 'react-intl'
import Card from '@objects/card'
import Button from '@objects/button'
import Headline from '@objects/headline'
import Copy from '@objects/copy'
import ParallaxImage from '@objects/parallaxImage'
import { focalPointsTable } from '@tools/focalPoints'

const useStyles = makeStyles((theme) => ({
  containerRoot: {
    display: 'flex',
    flexDirection: 'column-reverse',
    marginBottom: theme.spacing(10),
    [theme.breakpoints.up('lg')]: {
      flexDirection: (props) => (props.cardRight ? 'row-reverse' : 'row'),
      alignItems: 'center',
      marginBottom: theme.spacing(30),
    },
  },
  card: {
    zIndex: 100,
    marginTop: theme.spacing(-20),
    marginRight: (props) => (props.cardRight ? 0 : theme.spacing(4)),
    marginLeft: (props) => (props.cardRight ? theme.spacing(4) : 0),
    [theme.breakpoints.up('lg')]: {
      flex: 1,
      margin: (props) =>
        props.cardRight
          ? theme.spacing(0, 0, 0, -27)
          : theme.spacing(0, -27, 0, 0),
    },
  },
  headline: {
    marginBottom: theme.spacing(5),
    '&:last-child': {
      marginBottom: 0,
    },
  },
  subline: {
    ...theme.typography.h5,
    color: ({ sublinecolor }) =>
      sublinecolor === 'black'
        ? theme.palette.text.primary
        : theme.palette.red.main,
    fontWeight: 'bold',
  },
  copy: {
    ...theme.typography.teaser,
    '&:not(:last-child)': {
      marginBottom: theme.spacing(5),
    },
  },
  imageWrapper: {
    [theme.breakpoints.up('lg')]: {
      width: `calc(50% + ${theme.spacing(27)}px)`,
      flex: '0 0 auto',
    },
  },
  image: {
    [theme.breakpoints.up('lg')]: {
      maxWidth: theme.spacing(181),
      margin: (props) => (props.cardRight ? '0 0 0 auto' : '0'),
      display: 'block',
    },
  },
}))

function ImageCard({ children, ...props }) {
  const classes = useStyles(props)
  const {
    image,
    headline,
    copy,
    button: { label: buttonLabel, link: buttonLink, ...buttonProps } = {},
    containImage,
    subline,
    parallax,
    cardRight,
    barcolor,
    focalPoint,
  } = props
  const focalPoints = focalPointsTable
const intl = useIntl()
  if (!headline && !copy) {
    console.error('imageCard requires either a headline or copy')
  }

  function LinkWrapper({ children, className }) {
    if (buttonLink) {
      return (
        <Link className={className} to={buttonLink} tabIndex="-1">
          {children}
        </Link>
      )
    } else {
      return children
    }
  }

  return (
    <div className={classes.containerRoot} role="region"
    aria-label={intl.formatMessage({ id: 'arialabel.imagecard' })}
    >
      <Card
        className={classes.card}
        barOrientation={cardRight ? 'left' : 'right'}
        bind={cardRight ? 'right' : 'left'}
        bar={barcolor || (containImage ? 'red' : 'yellow')}
        transparentCard={false}
        stage={!containImage}
      >
        {subline && <div className={classes.subline}>{subline}</div>}
        {headline && (
          <Headline level={containImage ? 2 : 1} className={classes.headline}>
            <LinkWrapper>{headline}</LinkWrapper>
          </Headline>
        )}
        {copy && <Copy className={classes.copy} html={copy} />}
        {buttonLabel && (
          <Button className={classes.button} link={buttonLink} {...buttonProps}>
            {buttonLabel}
          </Button>
        )}
        {children}
      </Card>

      <div className={classes.imageWrapper}>
        <LinkWrapper className={containImage ? classes.image : ''}>
          {!!image && !!image.fluid && parallax ? (
            <ParallaxImage
              outerClassName={containImage ? classes.image : ''}
              image={image?.fluid || {}}
              scale={1.2}
              alt={image?.description || ''}
              focalPoint={focalPoint}
            />
          ) : (
            <Img
              className={containImage ? classes.image : ''}
              fluid={{ ...image?.fluid, media: `(min-width: 0px)` }}
              backgroundColor={true}
              alt={image?.description || ''}
              objectPosition={
                focalPoint ? focalPoints[focalPoint] : focalPoints['Center']
              }
            />
          )}
        </LinkWrapper>
      </div>
    </div>
  )
}

ImageCard.propTypes = {
  children: PropTypes.any,
  headline: PropTypes.string,
  subline: PropTypes.string,
  sublinecolor: PropTypes.oneOf(['black']),
  copy: PropTypes.string,
  button: PropTypes.object,
  barcolor: PropTypes.oneOf(['yellow']),
  image: PropTypes.object,
  focalPoint: PropTypes.oneOf(Object.keys(focalPointsTable)),
  containImage: PropTypes.bool,
  parallax: PropTypes.bool,
  cardRight: PropTypes.bool,
}

export default ImageCard

import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import useStaticModule from '@hooks/useStaticModule'
import { useIntl } from 'react-intl'

import Container from '@objects/container'
import TopicTeaserGrid from '@components/topicTeaserGrid'
import TripleText from '@components/tripleText'
import Stage from '@components/stage'
import ImageCard from '@components/imageCard'
import DialogTeaser from '@components/dialogTeaser'

export const frontmatter = {
  intl: {
    slug: {
      en: '',
    },
  },
}

function RootIndex({ data, pageContext }) {
  const intl = useIntl()
  const { getStaticFieldValue, getStaticFieldMedia } = useStaticModule(
    pageContext.modules
  )
  const stage = {
    image: getStaticFieldMedia('infoportal.stage', `infoportal.stage.image`),
    headline: getStaticFieldValue(
      'infoportal.stage',
      `infoportal.stage.headline`
    ),
    copy: getStaticFieldValue('infoportal.stage', `infoportal.stage.copy`),
    button: getStaticFieldValue('infoportal.stage', `infoportal.stage.button`),
  }
  const topics = get(data, 'contentfulTopicCategoryList.categories')
    .filter((filterTopic) => filterTopic.title && filterTopic.slug)
    ?.map((topic) => {
      return {
        headline: topic.title,
        copy: topic.stageCopy?.childMarkdownRemark?.html,
        image: topic.teaserImageWithFocalpoint
          ? topic.teaserImageWithFocalpoint.image.fluid
          : topic.teaserImage?.fluid,
        imageAlt: topic.teaserImageWithFocalpoint
          ? topic.teaserImageWithFocalpoint.image.description
          : topic.teaserImage?.description,
        focalPoint: topic.teaserImageWithFocalpoint?.focalPoint,
        slug: `${intl.formatMessage({ id: 'inform.path' })}${topic.slug}/`,
      }
    })
  const headline = get(data, 'contentfulTopicCategoryList.title')

  const tripleText = ['1', '2', '3'].map((count) => {
    return {
      headline: getStaticFieldValue(
        'infoportal.tripletext',
        `infoportal.tripletext.${count}.headline`
      ),
      copy: getStaticFieldValue(
        'infoportal.tripletext',
        `infoportal.tripletext.${count}.copy`
      ),
    }
  })

  const infoPortalImageCard = {
    headline: getStaticFieldValue(
      'infoportal.imagecard',
      `infoportal.imagecard.headline`
    ),
    copy: getStaticFieldValue(
      'infoportal.imagecard',
      `infoportal.imagecard.copy`
    ),
    button: getStaticFieldValue(
      'infoportal.imagecard',
      `infoportal.imagecard.button`
    ),
    image: getStaticFieldMedia(
      'infoportal.imagecard',
      `infoportal.imagecard.image`
    ),
  }

  return (
    <>
      <Stage
        title={stage.headline}
        ariaLabel={stage.headline}
        copy={stage.copy}
        image={stage.image}
        buttons={[
          {
            label: stage.button,
            link: '#topics',
            icon: 'ArrowDown',
          },
        ]}
      />
      <Container
      ariaLabel="arialabel.informieren"
      >
        <TripleText items={tripleText} />
      </Container>
      <ImageCard
        headline={infoPortalImageCard.headline}
        copy={infoPortalImageCard.copy}
        button={{
          label: infoPortalImageCard.button,
          link: intl.formatMessage({ id: 'faq.path' }),
          color: 'red',
          icon: 'TextArrow',
          type: 'secondary',
        }}
        image={infoPortalImageCard.image}
        containImage
        parallax
      />
      <Container id="topics"
      ariaLabel="arialabel.topicteasergrid"
      >
        <TopicTeaserGrid headline={headline} topics={topics} />
      </Container>
      {intl.locale === 'de' && (
        <DialogTeaser
          headline={intl.formatMessage({ id: 'teaser.onlinedialog.headline' })}
          copy={intl.formatMessage({ id: 'teaser.onlinedialog.copy' })}
          buttons={[
            {
              label: intl.formatMessage({ id: 'button.onlinedialog' }),
              link: intl.formatMessage({ id: 'dialog.path' }),
            },
            {
              label: intl.formatMessage({ id: 'button.createArticle' }),
              color: 'outline',
              create: true,
            },
          ]}
        />
      )}
    </>
  )
}

RootIndex.propTypes = {
  data: PropTypes.any,
  pageContext: PropTypes.object,
  modules: PropTypes.array,
}

export default RootIndex

export const pageQuery = graphql`
  query InformPageQuery($locale: String!) {
    contentfulTopicCategoryList(
      identifier: { eq: "topiccategories" }
      node_locale: { eq: $locale }
    ) {
      title
      categories {
        title
        slug
        stageCopy {
          childMarkdownRemark {
            html
          }
        }
        teaserImage {
          fluid(maxWidth: 704, quality: 90) {
            ...GatsbyContentfulFluid_noBase64
          }
          description
        }
        teaserImageWithFocalpoint {
          image {
            fluid(maxWidth: 704, quality: 90) {
              ...GatsbyContentfulFluid_noBase64
            }
            description
          }
          focalPoint
        }
      }
    }
  }
`

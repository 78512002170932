import React from 'react'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import { makeStyles, useTheme } from '@material-ui/core/styles'

import Container from '@objects/container'
import Headline from '@objects/headline'
import Copy from '@objects/copy'
import Button from '@objects/button'
import CreateContentButton from '@objects/button/createContentButton'

const useStyles = makeStyles((theme) => ({
  dialogTeaserCardRoot: {
    overflow: 'hidden',
    textAlign: 'center',
  },
  inner: {
    position: 'relative',
  },
  copy: {
    ...theme.typography.teaser,
    marginBottom: theme.spacing(10),
  },
  buttons: {
    marginTop: theme.spacing(-4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    [theme.breakpoints.up('md')]: {
      display: 'block',
      marginLeft: theme.spacing(-4),
      marginRight: theme.spacing(-4),
    },
  },
  button: {
    marginTop: theme.spacing(4),
    [theme.breakpoints.up('md')]: {
      marginLeft: theme.spacing(4),
      marginRight: theme.spacing(4),
    },
  },
  illustration: {
    position: 'absolute',
    width: '120px',
    left: theme.spacing(-5),
    bottom: theme.spacing(-26),
    transform: 'rotate(180deg)',
    [theme.breakpoints.up('md')]: {
      width: '266px',
      bottom: theme.spacing(-32),
      left: theme.spacing(-15),
    },
    [theme.breakpoints.up('lg')]: {
      width: '400px',
      bottom: theme.spacing(-45),
      left: theme.spacing(-35),
    },
    [theme.breakpoints.up('xl')]: {
      width: '440px',
      bottom: theme.spacing(-50),
      left: theme.spacing(-60),
    },
  },
}))

function DialogTeaserCard({ headline, copy, buttons }) {
  const theme = useTheme()
  const classes = useStyles()

  return (
    <Container
      className={classes.dialogTeaserCardRoot}
      background={theme.palette.background.grey}
      padded
      ariaLabel="arialabel.dialogteaser"
    >
      <div className={classes.inner}>
        <img
          className={classes.illustration}
          src="/img/illustration/illustration_grey_diagonal.svg"
          alt=""
        />
        <Grid container justify="center">
          <Grid item xs={12} sm={10} md={6}>
            <Headline level={2}>{headline}</Headline>
            <Copy className={classes.copy}>{copy}</Copy>
            <div className={classes.buttons}>
              {buttons.map((button) => {
                if (button.create) {
                  return (
                    <CreateContentButton
                      key={button.label}
                      className={classes.button}
                      type={'secondary'}
                      color={button.color || 'red'}
                    >
                      {button.label}
                    </CreateContentButton>
                  )
                } else {
                  return (
                    <Button
                      key={button.label}
                      className={classes.button}
                      color={button.color || 'red'}
                      link={button.link}
                      type={'secondary'}
                    >
                      {button.label}
                    </Button>
                  )
                }
              })}
            </div>
          </Grid>
        </Grid>
      </div>
    </Container>
  )
}

DialogTeaserCard.propTypes = {
  headline: PropTypes.string,
  copy: PropTypes.string,
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      link: PropTypes.string,
      color: PropTypes.string,
    })
  ),
}

export default DialogTeaserCard
